
//Plugin
//=require ../libs/slick-carousel/slick/slick.min.js
//=require ../libs/fancybox/dist/jquery.fancybox.min.js
//Plugin mmenu
//=require ./components/mmenu.js
//Plugin mask
//=require ../libs/jquery-mask-plugin/dist/jquery.mask.min.js


// Custom JS
// $(document).ready(function () {
// 	console.log('jQuery:' + jQuery.fn.jquery);
// });


// Toggle menu
//=require ./components/siteList__toggle.js


//oldScripts
//=require ./components/oldScripts.js


$(function() {
	// $.fancybox({
	//         scrolling   : 'hidden',
	//         helpers: {
	//             overlay: {
	//               locked: true
	//             }
	//         }
	//         });
	$(".modalbox").fancybox({
		scrolling : 'no'
	});
});


$(function() {

	if ( $(window).width() < 992 ) {
		$(".serviceMenuToggle").remove();
		$(".subSiteList__wrap").removeClass();
	}

});


//mmenu_config
//=require ./components/mmenu_config.js


//slick_config
//=require ./components/slick_config.js


//tab_and_slide
//=require ./components/tab_and_slide.js


//picePage
//=require ./components/picePage.js


//commerce__itemFormName
//=require ./components/commerce__itemFormName.js


//diafan_ajax
//=require ./components/diafan_ajax.js


// Quiz
//=require ./components/quiz.js


$(function() {

	if ( $(window).width()<992 ) {
		$(".aboutPage__wrap").append($(".aboutPage__title"));
	}

});


$(function() {

	$(".industry__tabsLink").eq(0).addClass('current');
	$(".industry__content").eq(0).addClass('current');
	
});


$(function() {
	if ( $(window).width() < 1199 ) {
		$(".price__table--v2").wrap('<div class="price__table__wrap"></div>');
	}
});


$('input[type=tel]').mask('+7(999)999-99-99');


// $(window).load(function() {
$(document).ready(function() {

	$(".industry__wrap--JS").addClass('show');
	$(".goodsInf__imgWrap--sliderJS").addClass('show');
	$(".getDiscount__quiz--JS").addClass('show');
	$(".aboutPage__slider--JS").addClass('show');
	$(".serviceExample__imgWrap").addClass('show');
	$(".serviceCase__slider--JS").addClass('show');

	$(".industry__slider--JS").slick();

});


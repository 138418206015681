

$(function() {
		$("").slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				draggable: !1,
				dots: !0,
				dotsClass: "slider__dots-main",
				arrows: !0,
				prevArrow: '<div class="slider__arrow-main slider__arrow-main--left">',
				nextArrow: '<div class="slider__arrow-main slider__arrow-main--right">',
				mobileFirst: !1,
				centerMode: !1,
				variableWidth: !0,
				adaptiveHeight: !1,
				focusOnSelect: !0
		})
}), $(function() {
		$(".customers__slide.slick-current.slick-active").addClass("active"), $(".customers__slider-js").on("afterChange", function(e, t, n, s) {
				$(".customers__slide").addClass("active")
		}), $(".customers__slider-js").on("beforeChange", function(e, t, n, s) {
				$(".customers__slide.slick-current.slick-active").removeClass("active")
		})
}), $(function() {
		$(".about__slideItem.slick-current").addClass("active"), $(".about__slider-js").on("afterChange", function(e, t, n, s) {
				$(".about__slideItem.slick-current").addClass("active")
		}), $(".about__slider-js").on("beforeChange", function(e, t, n, s) {
				$(".about__slideItem.slick-current").removeClass("active")
		})
}), $(document).ready(function() {
		$(".questions__itemCaption").on("click", function() {
				$(this).hasClass("active") ? ($(this).removeClass("active"), $(this).siblings(".questions__content").slideUp(300)) : ($(".questions__itemCaption").removeClass("active"), $(this).addClass("active"), $(".questions__content").slideUp(300), $(this).siblings(".questions__content").slideDown(300))
		})
}), $(function() {
		$(".price__itemWrap").on("click", "li:not(.active)", function() {
				$(this).addClass("active").siblings().removeClass("active").closest(".price__wrap").find(".price__tabsContent").removeClass("active").eq($(this).index()).addClass("active");
				var e = $(".price__item.active").text();
				$(".price__selectTypeText").text(e)
		})
});
var text = $(".price__item.active").text();
$(".price__selectTypeText").text(text), $(window).width() < 992 && ($(".price__selectType").on("click", function() {
		$(this).toggleClass("active").next().slideToggle("medium").css("display", "block")
}), $(".price__item").on("click", function() {
		$(this).parent().slideToggle("medium"), $(".price__selectType").toggleClass("active")
}));


$(function() {

	$('.gallery__btnWrap .button').wrapInner('<span>');


	$('.priceSelect__title--js').click(function() {
		$(this).parent().toggleClass('priceSelect--active');
		$(this).toggleClass('priceSelect__title--active');
		// $(this).next().slideToggle();
		$(this).next().slideToggle(100);
	});


	$('.pricePage h3').each(function(id){

		var elem_id = 'sec' + id;

		$(this).attr( 'id', elem_id );

		$('.priceSelect__list--js').append('<li class="priceSelect__listItem"><a href="#'+ elem_id +'" class="priceSelect__listItemLink">' + $(this).text() + '</a></li>');

	});

	$("a.priceSelect__listItemLink").click(function() {
		var elementClick = $(this).attr("href")
		var destination = $(elementClick).offset().top - 25;

		$("a.priceSelect__listItemLink").removeClass('priceSelect__listItemLink--active');
		$(this).addClass('priceSelect__listItemLink--active');

		jQuery("html:not(:animated),body:not(:animated)").animate({
			scrollTop: destination
		}, 800);
		return false;
	});


});

$(function(){$(window).width()<992&&$("#my-menu").mmenu(
	{
		extensions:["border-full"],
		navbar: {
			title:""
		},
		navbars: [
			{
				"position": "bottom",
				"content": $(".my-menu__info"),
			},
			{
				"position": "bottom",
				"content": $(".my-menu__tel"),
			}
		]
	}
)});

$(function() {
	setTimeout(function(){
		$("#my-menu").show();
	},100);
});
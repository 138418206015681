
$(document).ready(function() { 
	$(window).width() > 991 && $(".siteList__item--service").click(function() {
		$(".subSiteList__wrap").toggleClass("open"),
    $(".serviceMenuToggle__menuDots").fadeToggle(),
    $(".serviceMenuToggle__close").fadeToggle()
	})
}),
$(document).ready(function() {
	$(window).width() < 992 && $(".siteList__toggle").click(function() {
			$(".siteList__toggle").toggleClass("active"), $("#my-menu").toggleClass("mm-current mm-opened"), $(".header").toggleClass("active"), $("html").toggleClass("overflow"), $(".overlay").toggleClass("open")
	})
})

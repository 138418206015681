
$(document).ready(function() {
		function e(e) {
				var t, n = $("customTabs__link.current").index(),
						s = $(".customTabs .customTabs__link").length;
				$(".customTabs .customTabs__link").eq((n + t) % s).click();
		}
		$(".customTabs .customTabs__link").click(function() {
				var e = $(this).attr("data-tab");
				$(".customTabs .customTabs__link").removeClass("current"),
				$(".customTabs__content").removeClass("current"),
				$(this).addClass("current"),
				$("#" + e).addClass("current");
		});
		$(".customTabs .customIndustryTabs__link").click(function() {
				var e = $(this).attr("data-tab");
				$(".customTabs .customIndustryTabs__link").removeClass("current"),
				$(".customIndustryTabs__content").removeClass("current"),
				$(this).addClass("current"),
				$("#" + e).addClass("current");
		});
	$('.industry__tabsLink').click(function(){
		$(".industry__slider--JS").slick('slickSetOption', 'adaptiveHeight', false, true);
		$(".goodsInf__imgWrap--sliderJS").slick('slickSetOption', 'adaptiveHeight', true, true);
	});
});
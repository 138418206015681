
$(window).load(function() {

	$(".about__slider").css('overflow', 'visible');

}); 

// slick
// $(function(){
//   $('.about__slider-js').slick({
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     draggable: true,
//     dots: true,
//     customPaging : function(slider, i) {
//          return '	<svg class="progress" width="160" height="160" viewBox="0 0 160 160" xmlns="http://www.w3.org/2000/svg"><circle class="progress__stroke" cx="80" cy="80" r="54" fill="none" stroke="" stroke-width="9" /><circle class="progress__value" cx="80" cy="80" r="54" fill="none" stroke="url(#paint0_linear)" stroke-width="9" /><defs><linearGradient id="paint0_linear" x1="-2.62268e-07" y1="6" x2="12" y2="6" gradientUnits="userSpaceOnUse"><stop stop-color="#FFE178"/><stop offset="1" stop-color="#FFBA00"/></linearGradient></defs></svg>';
//      },
//     dotsClass: 'slider__dots-main',
//     pauseOnHover: true,
//     pauseOnDotsHover: false,
//     arrows: true,
//     prevArrow: '<div class="slider__arrow-main slider__arrow-main--left">',
//     nextArrow: '<div class="slider__arrow-main slider__arrow-main--right">',
//     mobileFirst: false,
//     centerMode: false,
//     variableWidth: true,
//     adaptiveHeight: false,
//     focusOnSelect: true,
//     autoplay: true,
//     autoplaySpeed: 6500,
//   });
// });

// $(function(){
//   $('.slick-slide').hover(function(){
//     $('.slider__dots-main').toggleClass('stop');
//   });
// });


$(function(){
	$('.goodsInf__imgWrap--sliderJS').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		draggable: true,
		// dots: true,
		// dotsClass: 'customSlick__dots',
		// customPaging : function(slider, i) {
		// 	return '<span class="customSlick__dotsItem"></span>';
		// },
		pauseOnHover: true,
		pauseOnDotsHover: false,
		arrows: true,
		prevArrow: '<div class="customSlick__arrow customSlick__arrow--left"><svg width="8" height="13"><use href="#sl-arrow"></use></svg></div>',
		nextArrow: '<div class="customSlick__arrow customSlick__arrow--right"><svg width="8" height="13"><use href="#sl-arrow"></use></svg></div>',
		centerMode: false,
		variableWidth: true,
		// adaptiveHeight: false,
		adaptiveHeight: true,
		focusOnSelect: true,
		autoplay: true,
		autoplaySpeed: 6500,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					arrows: false,
					variableWidth: false,
					adaptiveHeight: true,
					autoplay: false,
				}
			},
		]
	});

	$('.mnProject__imgWrap--sliderJS').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		draggable: true,
		pauseOnHover: true,
		pauseOnDotsHover: false,
		arrows: true,
		prevArrow: '<div class="customSlick__arrow customSlick__arrow--left"><svg width="8" height="13"><use href="#sl-arrow"></use></svg></div>',
		nextArrow: '<div class="customSlick__arrow customSlick__arrow--right"><svg width="8" height="13"><use href="#sl-arrow"></use></svg></div>',
		autoplay: false,
		autoplaySpeed: 6500,
		responsive: [
			{
				breakpoint: 992,
				settings: "unslick"
			}
		]
	});

	$(".industry__tabs--JS").slick({
		mobileFirst: true,
		slidesToShow: 1,
		slidesToScroll: 2,
		variableWidth: true,
		draggable: true,
		arrows: false,
		infinite: false,
		// infinite: true,
		focusOnSelect: true,
		responsive: [
			{
				breakpoint: 991,
				settings: "unslick"
			},
		]
	});
	
	$(".industry__slider--JS").slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		draggable: false,
		pauseOnHover: true,
		pauseOnDotsHover: false,
		adaptiveHeight: false,
		// arrows: true,
		arrows: false,
		prevArrow: '<div class="customSlick__arrow customSlick__arrow--larg industry__sliderArrow--left"><svg width="10" height="17"><use href="#sl-arrow-10-17"></use></svg></div>',
		nextArrow: '<div class="customSlick__arrow customSlick__arrow--larg industry__sliderArrow--right"><svg width="10" height="17"><use href="#sl-arrow-10-17"></use></svg></div>',
		asNavFor: '.industry__navSlider--JS',
		fade: true,
		cssEase: 'linear',
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2,
					draggable: true,
					fade: false,
					// dots: true,
				}
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
				}
			},
		]
	});

	// $(".industry__slideWrap.slick-current").addClass("active");
	$(".industry__slider--JS").on("afterChange",function(e,t,n,s){
		$(".industry__slideWrap").addClass("active")
	});
	$(".industry__slider--JS").on("beforeChange",function(e,t,n,s){
		$(".industry__slideWrap.slick-current").removeClass("active")
	});

	$(".industry__navSlider--JS").slick({
		slidesToShow: 6,
		slidesToScroll: 1,
		variableWidth: true,
		draggable: false,
		pauseOnHover: true,
		pauseOnDotsHover: false,
		arrows: false,
		asNavFor: '.industry__slider--JS',
		focusOnSelect: true,
		responsive: [
			{
				breakpoint: 992,
				settings: "unslick"
			},
		]
	});

	$(".aboutPage__slider--JS").slick({
		// slidesToShow: 2,
		slidesToShow: 2,
		slidesToScroll: 1,
		variableWidth: true,
		draggable: false,
		pauseOnHover: true,
		focusOnSelect: true,
		arrows: true,
		prevArrow: '<div class="customSlick__apArrow customSlick__apArrow--left"><svg width="9" height="14"><use href="#sl-arrow"></use></svg></div>',
		nextArrow: '<div class="customSlick__apArrow customSlick__apArrow--right"><svg width="9" height="14"><use href="#sl-arrow"></use></svg></div>',
		dots: true,
		dotsClass: 'customSlick__apLineDots',
		customPaging : function(slider, i) {
			return '<span class="customSlick__apLineDotsItem"></span>';
		},
		responsive: [
			{
				breakpoint: 992,
				settings: {
					draggable: true,
				}
			},
			{
				breakpoint: 767,
				settings: {
					arrows: false,
				}
			},
		]
	});

	$(".serviceExample__imgWrap--JS").slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		draggable: false,
		pauseOnHover: true,
		arrows: true,
		prevArrow: '<div class="customSlick__arrow customSlick__arrow--left"><svg width="8" height="13"><use href="#sl-arrow"></use></svg></div>',
		nextArrow: '<div class="customSlick__arrow customSlick__arrow--right"><svg width="8" height="13"><use href="#sl-arrow"></use></svg></div>',
		dots: true,
		dotsClass: 'customSlick__dottedDots',
		customPaging : function(slider, i) {
			return '<span class="customSlick__dottedDotsItem"></span>';
		},
	});

	$(".commerce__list--JS").slick({
		mobileFirst: true,
		slidesToShow: 1,
		slidesToScroll: 2,
		variableWidth: true,
		draggable: true,
		arrows: false,
		infinite: false,
		focusOnSelect: true,
		dots: true,
		dotsClass: 'customSlick__lineDots',
		customPaging : function(slider, i) {
			return '<span class="customSlick__lineDotsItem"></span>';
		},
		responsive: [
			{
				breakpoint: 767,
				settings: "unslick"
			},
		]
	});

	$(".mnProject__list--JS").slick({
		mobileFirst: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		variableWidth: true,
		draggable: true,
		arrows: false,
		infinite: false,
		focusOnSelect: true,
		responsive: [
			{
				breakpoint: 991,
				settings: "unslick"
			},
		]
	});

	$(".tabs__linkWrap-js").slick({
		mobileFirst: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		variableWidth: true,
		draggable: true,
		arrows: false,
		infinite: false,
		focusOnSelect: true,
		dots: true,
		dotsClass: 'customSlick__lineDots customSlick__lineDots--tabs',
		customPaging : function(slider, i) {
			return '<span class="customSlick__lineDotsItem"></span>';
		},
		responsive: [{
			breakpoint: 991,
			settings: "unslick"
		}]
	});

	$(".customers__slider-js").slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		draggable: !1,
		dots: !1,
		dotsClass: "slider__dots",
		arrows: !0,
		prevArrow: '<div class="slider__arrow slider__arrow--left">',
		nextArrow: '<div class="slider__arrow slider__arrow--right">',
		mobileFirst: !1,
		centerMode: !1,
		variableWidth: !1,
		adaptiveHeight: !1,
		responsive: [{
			breakpoint: 769,
			settings: {
				arrows: !1,
				draggable: true,
			}
		}]
	});

	$(".gallery__list--JS").slick({
		mobileFirst: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		variableWidth: true,
		draggable: true,
		arrows: false,
		infinite: false,
		focusOnSelect: true,
		responsive: [
			{
				breakpoint: 991,
				settings: "unslick"
			},
		]
	});

	$(".serviceCase__slider--JS").slick({
		// slidesToShow: 2,
		slidesToShow: 2,
		slidesToScroll: 1,
		variableWidth: true,
		draggable: false,
		pauseOnHover: true,
		arrows: true,
		prevArrow: '<div class="customSlick__apArrow customSlick__apArrow--blc customSlick__apArrow--left"><svg width="9" height="14"><use href="#sl-arrow"></use></svg></div>',
		nextArrow: '<div class="customSlick__apArrow customSlick__apArrow--blc customSlick__apArrow--right"><svg width="9" height="14"><use href="#sl-arrow"></use></svg></div>',
		dots: true,
		dotsClass: 'customSlick__apLineDots customSlick__apLineDots--blc',
		customPaging : function(slider, i) {
			return '<span class="customSlick__apLineDotsItem"></span>';
		},
		responsive: [
			{
				breakpoint: 991,
				settings: {
					draggable: true,
					arrows: false,
					dots: false,
				}
			},
		]
	});
}); 
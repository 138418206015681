
$(function() {

	$(".commerce__item").click(function() {
		var btnName = $(this).find(".commerce__name").text();
		$(".commerce__item").removeClass("active");
		$(this).addClass("active");
		$(".feedback__selectInstitutionWrap").addClass('show');
		$(".feedback__selectInstitution").text(btnName);
		$(".feedback__selectInstitutionInput").val(btnName);
	});

});
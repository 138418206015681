
$(function() {

	diafan_ajax.success['feedback_add'] = function(form, response){
		if(response.result)
		{

			$.fancybox.close();

			setTimeout(function(){
				$.fancybox.open({src:'#modal-thanks',type:'inline'});
			},200);


			setTimeout(function(){
				$.fancybox.close();
			},3500);

		}
	}

});

if ($(".getDiscount").length > 0) {
  var currentTab = 0;
  showTab(currentTab);

  document.getElementById("nextBtn").disabled = true;

  function showTab(n) {
    var x = document.getElementsByClassName("getDiscount__quizItem");
    x[n].style.display = "block";

    if (n == 0 || n == x.length - 1) {
      // document.getElementById("prevBtn").style.display = "none";
      document.getElementById("prevBtn").disabled = true;
    } else {
      document.getElementById("prevBtn").disabled = false;
    }

    if (n == x.length - 1) {
      document.getElementById("prevBtn").style.display = "none";
      document.getElementById("nextBtn").style.display = "none";

      $(".getDiscount__quizPagItem").hide();
      $(".getDiscount__title").hide();
      $(".getDiscount__text").hide();
    } else {
      document.getElementById("nextBtn").style.display = "flex";
    }
    if (n == x.length - 2) {
      document.getElementById("nextBtn").innerHTML =
        'Рассчитать<svg class="getDiscount__quizBtnArrow" width="18" height="12"><use href="#quiz-arrow"></use></svg>';
    } else {
      document.getElementById("nextBtn").innerHTML =
        'Далее<svg class="getDiscount__quizBtnArrow" width="18" height="12"><use href="#quiz-arrow"></use></svg>';
    }

    fixStepIndicator(n);
  }

  $(".getDiscount__quizItem__radioInp").click(function () {
    var x, y, i;
    x = document.getElementsByClassName("getDiscount__quizItem");
    y = x[currentTab].getElementsByTagName("input");

    for (i = 0; i < y.length; i++) {
      if (y[i].checked) {
        document.getElementById("nextBtn").disabled = false;
      }
    }
    console.log("input");
  });

  function nextTab(n) {
    if (validateForm()) {
      var x = document.getElementsByClassName("getDiscount__quizItem");

      x[currentTab].style.display = "none";

      currentTab = currentTab + n;

      showTab(currentTab);
    }
  }

  function prevTab(n) {
    var x = document.getElementsByClassName("getDiscount__quizItem");

    inp = x[currentTab].getElementsByTagName("input");

    inpPrev = x[currentTab - 1].getElementsByTagName("input");

    for (i = 0; i < inp.length; i++) {
      if (inp[i].checked) {
        inp[i].checked = false;
      }
    }

    for (i = 0; i < inpPrev.length; i++) {
      if (inpPrev[i].checked) {
        inpPrev[i].checked = false;
      }
    }

    x[currentTab].style.display = "none";

    currentTab = currentTab + n;

    showTab(currentTab);
  }

  function validateForm() {
    var x,
      y,
      i,
      valid = false;
    x = document.getElementsByClassName("getDiscount__quizItem");
    y = x[currentTab].getElementsByTagName("input");

    for (i = 0; i < y.length; i++) {
      if (y[i].checked) {
        document.getElementsByClassName("getDiscount__quizPagItem")[
          currentTab
        ].className += " finish";

        document.getElementById("nextBtn").disabled = true;

        valid = true;

        return valid;
      }
    }

    return valid;
  }

  function fixStepIndicator(n) {
    var i,
      x = document.getElementsByClassName("getDiscount__quizPagItem");
    for (i = 0; i < x.length; i++) {
      x[i].className = x[i].className.replace(" active", "");
    }

    x[n].className += " active";
  }
}
